import { isDOMElement } from './../../../utils/isDOMElement';
import { formatStyleObjectToString, StyleObject } from '../../style-object';
import { getScrollLeft, getScrollTop } from '../../../utils/getScroll';

export interface TooltipConfig {
    text1: string;
    text2: string;
    text3: string;
    tooltipStyle?: StyleObject;
    onClickTooltipButton?: (e?: MouseEvent) => void;
    position?: 'top' | 'bottom' | 'left' | 'right';
}

let defaultTooltipStyle: StyleObject = {};

let tooltipEleExist = false;
export let tooltipEle: HTMLElement | null = null;

export function createTooltip(targetEle: HTMLElement | null, config: TooltipConfig, insertDOM?: string | HTMLElement) {
    let bindDOM: HTMLElement = (typeof insertDOM === 'string'
        ? document.querySelector(insertDOM)
        : insertDOM) as HTMLElement;
    if (!isDOMElement(bindDOM)) bindDOM = document.body;
    let tooltipContent: HTMLElement;
    let text1Ele: HTMLElement;
    let text2Ele: HTMLElement;
    let text3Ele: HTMLElement;
    let tooltipTriangle: HTMLElement;
    if (!tooltipEle) {
        tooltipEle = document.createElement('div');
        tooltipEle.id = 'guide-tooltip';
        tooltipEle.classList.add('guide-tooltip');
        tooltipContent = document.createElement('div');
        tooltipContent.id = 'guide-tooltip-content';
        text1Ele = document.createElement('div');
        text2Ele = document.createElement('div');
        text3Ele = document.createElement(config?.onClickTooltipButton ? 'button' : 'div');
        text1Ele.id = 'guide-tooltip-text1';
        text2Ele.id = 'guide-tooltip-text2';
        text3Ele.id = 'guide-tooltip-text3';
        tooltipTriangle = document.createElement('div');
        tooltipTriangle.id = 'guide-tooltip-triangle';
    } else {
        tooltipEleExist = true;
        tooltipContent = tooltipEle.querySelector('#guide-tooltip-content') as HTMLElement;
        text1Ele = tooltipEle.querySelector('#guide-tooltip-text1') as HTMLElement;
        text2Ele = tooltipEle.querySelector('#guide-tooltip-text2') as HTMLElement;
        text3Ele = tooltipEle.querySelector('#guide-tooltip-text3') as HTMLElement;
        text3Ele?.remove();
        text3Ele = document.createElement(config?.onClickTooltipButton ? 'button' : 'div');
        text3Ele.id = 'guide-tooltip-text3';
        tooltipTriangle = tooltipEle.querySelector('#guide-tooltip-triangle') as HTMLElement;
    }

    if (!tooltipEleExist) bindDOM.append(tooltipEle);

    tooltipEle.style.cssText = formatStyleObjectToString({
        ...defaultTooltipStyle,
        ...(config?.tooltipStyle || {})
    });

    text1Ele.innerHTML = config?.text1;
    text2Ele.innerHTML = config?.text2;
    text3Ele.innerHTML = config?.text3;
    text1Ele.style.cssText = formatStyleObjectToString({ fontSize: '14px', color: '#fff' });
    text2Ele.style.cssText = formatStyleObjectToString({ fontSize: '14px', color: '#fff', marginTop: '24px' });
    if (!config?.onClickTooltipButton)
        text3Ele.style.cssText = formatStyleObjectToString({
            fontSize: '16px',
            color: '#fff',
            marginTop: '24px',
            fontWeight: 500
        });
    if (!tooltipEleExist) {
        tooltipContent?.append(text1Ele, text2Ele, text3Ele);
        tooltipEle.append(tooltipContent, tooltipTriangle);
    } else tooltipContent?.append(text3Ele);
    if (config?.onClickTooltipButton) {
        text3Ele.classList.add('guide-tooltip-btn');
        text3Ele.addEventListener('click', (event: Event) => {
            event.stopPropagation();
            config?.onClickTooltipButton?.(event as MouseEvent);
        });
    }

    if (
        !targetEle ||
        config?.tooltipStyle?.left ||
        config?.tooltipStyle?.right ||
        config?.tooltipStyle?.top ||
        config?.tooltipStyle?.bottom ||
        config?.tooltipStyle?.transform
    ) {
        tooltipTriangle.style.opacity = '0';
        tooltipEle.style.left = (config?.tooltipStyle?.left as string) || 'initial';
        tooltipEle.style.top = (config?.tooltipStyle?.top as string) || 'initial';
        tooltipEle.style.right = (config?.tooltipStyle?.right as string) || 'initial';
        tooltipEle.style.bottom = (config?.tooltipStyle?.bottom as string) || 'initial';
        tooltipEle.style.transform = config?.tooltipStyle?.transform || 'initial';
        if (
            !targetEle &&
            !config?.tooltipStyle?.left &&
            !config?.tooltipStyle?.right &&
            !config?.tooltipStyle?.top &&
            !config?.tooltipStyle?.bottom &&
            !config?.tooltipStyle?.transform
        ) {
            const tooltipBoundary = tooltipEle.getBoundingClientRect();
            tooltipEle.style.left = `calc(50% - ${tooltipBoundary.width / 2}px)`;
            tooltipEle.style.top = `calc(50% - ${tooltipBoundary.height / 2}px)`;
        }
        return;
    }
    tooltipTriangle.style.opacity = '1';
    const tooltipBoundary = tooltipEle.getBoundingClientRect();
    if (targetEle !== null) {
        let isVerticalCenter = false;
        const targetBoundaryFixed = targetEle.getBoundingClientRect();
        const scrollTop = getScrollTop();
        const scrollLeft = getScrollLeft();
        const targetBoundary = {
            width: targetBoundaryFixed.width,
            height: targetBoundaryFixed.height,
            top: targetBoundaryFixed.top + scrollTop,
            bottom: targetBoundaryFixed.bottom + scrollTop,
            left: targetBoundaryFixed.left + scrollLeft,
            right: targetBoundaryFixed.right + scrollLeft
        };
        console.log('targetBoundary', targetBoundary);
        if (targetBoundary.left - tooltipBoundary.width >= 20) {
            tooltipEle.style.left = targetBoundary.left - tooltipBoundary.width - 20 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width - 13 + 'px';
            tooltipTriangle.style.transform = `rotate(45deg)`;
        } else if (
            targetBoundary.right + tooltipBoundary.width + 20 > 0 &&
            targetBoundary.right + tooltipBoundary.width + 20 <= bindDOM.offsetWidth
        ) {
            tooltipEle.style.left = targetBoundary.right + 20 + 'px';
            tooltipTriangle.style.left = -11 + 'px';
            tooltipTriangle.style.transform = `rotate(-135deg)`;
        } else {
            tooltipEle.style.left = targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2 + 'px';
            isVerticalCenter = true;
            tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
        }

        let tooltilTop = 0;
        if (isVerticalCenter) {
            tooltilTop = targetBoundary.top - tooltipBoundary.height - 20;
            if (tooltilTop >= 0) {
                tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height + 12 + 'px';
                tooltipTriangle.style.transform = `rotate(135deg)`;
            } else {
                tooltipEle.style.top = targetBoundary.bottom + 20 + 'px';
                tooltipTriangle.style.top = -24 + 'px';
                tooltipTriangle.style.transform = `rotate(90deg)`;
            }
        } else {
            tooltilTop = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2;
            if (tooltilTop > 0 && tooltilTop <= bindDOM.offsetHeight) {
                tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height / 2 - 12 + 'px';
            } else if ((tooltilTop = targetBoundary.bottom - tooltipBoundary.height) >= 0) {
                tooltipEle.style.top = tooltilTop + 'px';
                tooltipTriangle.style.top = tooltipBoundary.height - targetBoundary.height / 2 - 12 + 'px';
            } else {
                tooltipEle.style.top = targetBoundary.top + 'px';
                tooltipTriangle.style.top = targetBoundary.height / 2 - 12 + 'px';
            }
        }
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        if (config?.position === 'top') {
            tooltipEle.style.top = targetBoundary.top - tooltipBoundary.height - 20 + 'px';
            tooltipEle.style.left = targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height - 13 + 'px';
            tooltipTriangle.style.transform = `rotate(135deg)`;
        } else if (config?.position === 'bottom') {
            const tooltipLeft = Math.min(
                windowWidth - 20 - tooltipBoundary.width,
                targetBoundary.left - (tooltipBoundary.width - targetBoundary.width) / 2
            );
            tooltipEle.style.top = targetBoundary.bottom + 20 + 'px';
            tooltipEle.style.left = tooltipLeft + 'px';
            // tooltipTriangle.style.left = tooltipBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.left =
                Math.abs(tooltipLeft - targetBoundary.left) + targetBoundary.width / 2 - 12 + 'px';
            tooltipTriangle.style.top = -12 + 'px';
            tooltipTriangle.style.transform = `rotate(-45deg)`;
        } else if (config?.position === 'left') {
            tooltipEle.style.left = targetBoundary.left - tooltipBoundary.width - 20 + 'px';
            tooltipEle.style.top = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2 + 'px';
            tooltipTriangle.style.left = tooltipBoundary.width - 12 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height / 2 - 24 + 'px';
            tooltipTriangle.style.transform = `rotate(45deg)`;
        } else if (config?.position === 'right') {
            tooltipEle.style.left = targetBoundary.right + 20 + 'px';
            tooltipEle.style.top = (targetBoundary.bottom + targetBoundary.top) / 2 - tooltipBoundary.height / 2 + 'px';
            tooltipTriangle.style.left = -24 + 'px';
            tooltipTriangle.style.top = tooltipBoundary.height / 2 - 12 + 'px';
            tooltipTriangle.style.transform = `rotate(-45deg)`;
        }
    }
}

export function deleteTooltip() {
    if (tooltipEle) {
        tooltipEle.remove();
        tooltipEle = null;
        tooltipEleExist = false;
    }
}

const css = `
    #guide-tooltip, .guide-tooltip {
        position: absolute;
        width: 400px;
        padding: 32px;
        border-radius: 10px;
        background-color: #091E42;
        border: 1px solid #344563;
        box-shadow: 0 16px 20px 0 rgba(9, 30 ,66, 0.28);
        z-index: 8996;
        opacity: 1;
        transition: all .3s ease;
        top: 0px;
        left: calc(50vw - 300px);
        contain: layout style;
    }
    .guide-tooltip-text1, .guide-tooltip-text2 {
        font-size: 14px;
        color: #fff;
    }
    .guide-tooltip-text2, .guide-tooltip-text3 {
        margin-top: 24px;
    }
    .guide-tooltip-text3 {
        font-size: 16px;
    }
    .guide-tooltip-btn {
        all: unset;
        position: relative;
        height: 40px;
        padding: 0 16px;
        margin-top: 20px;
        background-color: white;
        color: #091E42;
        font-size: 16px;
        border-radius: 2px;
        cursor: pointer;
        &:disabled {
            opacity: .5;
            cursor: not-allowed;
        }
    }
    .guide-tooltip-btn:disabled {
        opacity: .5;
        cursor: not-allowed;
    }
    .guide-tooltip-btn::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: all 0.2s ease;
    }
    .guide-tooltip-btn:hover::before {
        background-color: rgba(9, 30, 66, 0.06);
    }
    #guide-tooltip-triangle {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        top: 0;
        background-color: #091e42;
        border-top: 1px solid #344563;
        border-right: 1px solid #344563;
        transform(-45deg);
    }

`;

const head = document.head || document.getElementsByTagName('head')[0];
const style = document.createElement('style');
style.type = 'text/css';
head?.appendChild(style);
style?.appendChild(document?.createTextNode(css));
