



































































































import { defineComponent, ref, computed, onMounted, watch, nextTick, shallowRef } from '@vue/composition-api';
const CLOSE_EVENT = 'close';
const OPEN_EVENT = 'open';
const CLOSED_EVENT = 'closed';
const OPENED_EVENT = 'opened';
const CONFIRM_EVENT = 'confirm';
const UPDATE_MODEL_EVENT = 'update:modelValue';
export default defineComponent({
    name: 'PtDialog',
    props: {
        appendToBody: {
            type: Boolean,
            default: false
        },
        beforeClose: {
            type: Function
        },
        destroyOnClose: {
            type: Boolean,
            default: false
        },
        center: {
            type: Boolean,
            default: false
        },
        customClass: {
            type: String,
            default: ''
        },
        customBodyHeight: {
            type: String,
            default: '50vh'
        },
        closeOnClickModal: {
            type: Boolean,
            default: false
        },
        closeOnPressEscape: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: true
        },
        showClose: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        openDelay: {
            type: Number,
            default: 0
        },
        closeDelay: {
            type: Number,
            default: 0
        },
        modelValue: {
            type: Boolean,
            required: true
        },
        modalClass: String,
        width: {
            type: String
        },
        size: {
            type: String,
            default: 'medium'
        },
        hasScroll: {
            type: Boolean,
            default: false
        },
        iconKey: {
            type: String
        },
        cancelButtonInfo: {
            type: Object,
            default: () => ({
                color: 'darkblue'
            })
        },
        cancelText: {
            type: String
        },
        showCancelButton: {
            type: Boolean,
            default: true
        },
        confirmButtonInfo: {
            type: Object,
            default: () => ({
                color: 'green'
            })
        },
        confirmText: {
            type: String
        },
        showConfirmButton: {
            type: Boolean,
            default: true
        },
        hasFooter: {
            type: Boolean,
            default: true
        },
        hasHeader: {
            type: Boolean,
            default: true
        },
        zIndex: {
            type: Number
        }
    },
    emits: [OPEN_EVENT, OPENED_EVENT, CLOSE_EVENT, CLOSED_EVENT, UPDATE_MODEL_EVENT, CONFIRM_EVENT],
    setup(props, ctx) {
        const dialogRef = ref(null);
        const dialog = ref<HTMLElement>();
        function useDialog(props, ctx, targetRef) {
            const visible = ref(false);
            const closed = ref(false);
            const openTimer = ref(null);
            const closeTimer = ref(null);
            const rendered = ref(false);
            const modalRef = ref(null);
            const normalizeWidth = width => {
                if (typeof width == 'number') return `${width}px`;
                else return width;
            };

            const style = computed(() => {
                const style = {};
                if (!props.fullscreen) {
                    if (props.width) {
                        style.width = normalizeWidth(props.width);
                    }
                }
                return style;
            });

            function handleConfirm() {
                ctx.emit(CONFIRM_EVENT);
            }

            function afterEnter() {
                ctx.emit(OPENED_EVENT);
            }

            function afterLeave() {
                ctx.emit(CLOSED_EVENT);
                ctx.emit(UPDATE_MODEL_EVENT, false);
                if (props.destroyOnClose) {
                    rendered.value = false;
                }
            }

            function beforeLeave() {
                ctx.emit(CLOSE_EVENT);
            }

            function clearTimer(timer) {
                clearTimeout(timer.value);
                timer.value = null;
            }
            function open() {
                clearTimer(closeTimer);
                clearTimer(openTimer);
                if (props.openDelay && props.openDelay > 0) {
                    openTimer.value = window.setTimeout(() => {
                        openTimer.value = null;
                        doOpen();
                    }, props.openDelay);
                } else {
                    doOpen();
                }
            }

            function close() {
                clearTimer(openTimer);
                clearTimer(closeTimer);

                if (props.closeDelay && props.closeDelay > 0) {
                    closeTimer.value = window.setTimeout(() => {
                        closeTimer.value = null;
                        doClose();
                    }, props.closeDelay);
                } else {
                    doClose();
                }
            }

            function hide(shouldCancel: boolean) {
                if (shouldCancel) return;
                closed.value = true;
                visible.value = false;
            }

            function handleClose() {
                if (props.beforeClose) {
                    props.beforeClose(hide);
                } else {
                    close();
                    ctx.emit(CLOSE_EVENT);
                }
            }

            function onModalClick() {
                if (props.closeOnClickModal) {
                    handleClose();
                }
            }

            function doOpen() {
                if (typeof window === 'undefined') {
                    return;
                }

                visible.value = true;
            }

            function doClose() {
                visible.value = false;
                if (props.appendToBody) dialog.value?.remove();
            }

            watch(
                () => props.modelValue,
                val => {
                    if (val) {
                        closed.value = false;
                        open();
                        rendered.value = true; // enables lazy rendering
                        ctx.emit(OPEN_EVENT);

                        nextTick(() => {
                            if (targetRef.value) {
                                targetRef.value.scrollTop = 0;
                            }
                        });
                    } else {
                        if (visible.value) {
                            close();
                        }
                    }
                }
            );

            onMounted(() => {
                if (props.modelValue) {
                    visible.value = true;
                    rendered.value = true; // enables lazy rendering
                    open();
                }
                if (props.appendToBody) {
                    document.body.appendChild(dialog.value);
                }
            });

            return {
                handleConfirm,
                afterEnter,
                afterLeave,
                beforeLeave,
                handleClose,
                onModalClick,
                closed,
                dialogRef,
                style,
                rendered,
                modalRef,
                visible
            };
        }
        return {
            ...useDialog(props, ctx, dialogRef),
            dialogRef,
            dialog
        };
    }
});
