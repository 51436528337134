function checkProtocol(url) {
    const reg = /^(https?:\/\/)/;
    return reg.test(url);
}
export default {
    formatPreviewUrl(url, key, value) {
        const hasProtocol = checkProtocol(url);
        if (!hasProtocol) {
            url = `http://${url}`;
        }
        const urlObj = window.pt.parseURL(url);
        if (urlObj.query === '' && urlObj.hash && urlObj.hash.indexOf('?') !== -1) {
            urlObj.hash += `&${key}=${value}`;
        } else if (urlObj.query !== '') {
            urlObj.query += `&${key}=${value}`;
        } else {
            urlObj.query = `?${key}=${value}`;
        }
        urlObj.port = urlObj.port ? ':' + urlObj.port : '';
        const result = urlObj.hash
            ? `${urlObj.protocol}://${urlObj.host}${urlObj.port}${urlObj.path}${urlObj.query}#${urlObj.hash}`
            : `${urlObj.protocol}://${urlObj.host}${urlObj.port}${urlObj.path}${urlObj.query}`;
        return result;
    },

    getDomain(hostname) {
        const urlRegex = /[a-z0-9][a-z0-9-]+\.[a-z.]{2,6}$/i;
        const langUrlRegex = /[a-z0-9][a-z0-9-]*\.[a-z]+$/i;
        var reg = urlRegex;
        var name = hostname.split('.');
        name = name[name.length - 1];
        if (name.length > 4 || name === 'com' || name === 'org') {
            reg = langUrlRegex;
        }
        const domain = hostname.match(reg);
        return domain ? domain[0] : '';
    },

    updateUrlParams(uri, key, value) {
        if (!value) {
            return uri;
        }
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + '=' + value + '$2');
        } else {
            return uri + separator + key + '=' + value;
        }
    },
    /**
     * 解析url
     * @param urlString  // https://www.ptengine.jp:8080?a=b#ddd
     * @returns  // {protocol: "https:", hostname: "www.ptengine.jp", port: "8080", pathname: "/", search: "?a=b", hash: "#ddd"}
     *
     */
    parseURL(urlString) {
        try {
            const urlObj = new URL(urlString);
            return urlObj;
        } catch (e) {
            const urlRegex = /^(?:([a-z]+):)?(\/\/(?:([^\/:@]+)(?::([^\/:@]+))?@)?([^\/?#:]+)(?::(\d+))?)?(\/[^?#]*)?(?:\?([^#]*))?(?:#(.*))?$/i;
            const match = urlString.match(urlRegex) || [];
            return {
                protocol: match?.[1] || '',
                hostname: match?.[5] || '',
                port: match?.[6] || '',
                pathname: match?.[7] || '',
                search: match?.[8] ? '?' + match?.[8] : '',
                hash: match?.[9] ? '#' + match?.[9] : ''
            };
        }
    },
    urlInsertParam(urlString, param, useHashParam) {
        urlString = urlString.indexOf('http') > -1 ? urlString : 'http://' + urlString;
        let { protocol, hostname, port, pathname, search, hash } = this.parseURL(urlString);
        if (useHashParam && !hash) {
            hash = `#${param}`;
        } else {
            search += search.indexOf('?') > -1 ? '&' : '?';
            search += param;
        }
        return `${protocol}//${hostname}${port ? ':' + port : ''}${pathname}${search}${hash}`;
    }
};
